#playerSelector {
  width: 100%;
  height: 100%;
  z-index: 10;
  background-color: #1a1a1a;
  padding: 30px;
  position: fixed;
  top: 0;
  left: 0;
}

.playerChoice {
  width: 190px;
  height: 190px;
  border: 1px solid #fff;
  margin: 20px;
  padding: 10px;
  display: inline-block;
}

.playerChoice:hover {
  background-color: #292828;
  border: 1px dashed #fff;
}

.playerChoice img {
  padding: 30px;
}

.clientVersion {
  color: gray;
  position: absolute;
  bottom: 100px;
  right: 100px;
}

/*# sourceMappingURL=index.fc92b5a0.css.map */
