#playerSelector{
    position: fixed;
    padding: 30px;
    top: 0px;
    left: 0px;
    width:100%;
    height: 100%;
    background-color: rgb(26, 26, 26);
    z-index: 10;
}

.playerChoice {
    display: inline-block;
    margin: 20px;
    padding: 10px;
    width: 190px;
    height: 190px;
    border: 1px solid white;
}

.playerChoice:hover{
    border: 1px dashed white;
    background-color: rgb(41, 40, 40);
}

.playerChoice img {
    padding: 30px;
}

.clientVersion {
    position: absolute;
    bottom: 100px;
    right: 100px;
    color: gray;
}